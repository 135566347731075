import React from "react"
import Slider from "react-slick";

import BtnLink from "../../../addon/Link"
import BottomSection from "./bottomSection";
import Link from "../../../addon/Link";

const ImageSection = (el) => {
    const defaultBannerImage = "https://via.placeholder.com/1903x792"
    const altText = el?.bannerImage?.alt || "Hinduja Hospital" 
    return (
        <picture className="banner_img">
            {el?.relationships?.bannerImage?.uri?.url ?
                <>
                    <img
                        src={el?.relationships?.mobilebannerImage?.uri?.url}
                        alt={altText} width="1920" height="950"
                        className="d-md-none lazyload"
                    />
                    <img
                        src={el?.relationships?.bannerImage?.uri?.url}
                        alt={altText} width="1920" height="950"
                        className="d-md-block d-none lazyload"
                    />
                </>
                :
                <>
                    <source media="(min-width:1024px)" srcset={defaultBannerImage}/>
                    <source media="(min-width:300px)" srcset={defaultBannerImage} />
                    <img className="lazyload" src={defaultBannerImage} alt={altText} width="1920" height="950" />
                </>
            }
        </picture>
    )
}
const HomeBanner = (props) => {

    const {
        content,
        bottomSectionContent
    } = props

    let data = (content && content?.relationships && content?.relationships?.components) ? content.relationships.components : {}
    
    data = Array.isArray(data) ? data : [data , data] ;
    
    return (
        <>
            <Slider className="home-page-slider" autoplay={true} adaptiveHeight={true}>
                {data.map((el, i) => {
                    const title = el && el.title ? el.title : ''
                    const subTitle = el&& el.subtitle ? el.subtitle : ''
                        
                        const btnLink = el && el.bannerLink ? {  link: { ...el.bannerLink } } : null                                                                     
                        if(el?.bannerLink?.title){
                            return(
                                <div className="boxWrap" key={i}>
                                    {ImageSection(el)}
                                    <div className="banner_content">
                                        <div className="container">
                                            <div className="banner_txt">
                                                <h2>{title}</h2>
                                                <p>{subTitle}</p>
                                                {
                                                btnLink &&
                                                    <BtnLink
                                                        data={btnLink}
                                                        classes="btn btn-primary"
                                                        />
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                )
                        }else{
                            return(
                                <div className="boxWrap" key={i}>
                                    <Link data={btnLink}>
                                        {ImageSection(el)}
                                    </Link>
                                </div>
                            )
                        }
                    })
                }
            </Slider>
            <BottomSection
                content={bottomSectionContent}
            />
        </>
    )
}


export default HomeBanner
